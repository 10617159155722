import { BorderBox, Divider, Text } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import * as React from 'react';

import Bugsnag from '../utils/bugsnag';

const BugsnagErrorBoundary =
  Bugsnag.getPlugin('react').createErrorBoundary(React);

FallbackComponent.propTypes = {
  error: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
};

function FallbackComponent({ error, info }) {
  return (
    <BorderBox
      border="1"
      borderColor="state.error"
      color="state.error"
      pt="2"
      pb="1"
      px="3"
    >
      <Text.small display="block" fontWeight="bold">
        Ett fel inträffade!
      </Text.small>
      <Text.small display="block">
        {typeof error === 'object'
          ? error.message || 'Okänt fel, försök igen'
          : error}
      </Text.small>
      {process.env.NODE_ENV === 'development' && (
        <>
          <Divider />
          <Text.small display="block" mt="3">
            {info.componentStack}
          </Text.small>
        </>
      )}
    </BorderBox>
  );
}

const ErrorBoundary = ({ children }) => (
  <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
    {children}
  </BugsnagErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

ErrorBoundary.displayName = 'AppErrorBoundary';

export default ErrorBoundary;
