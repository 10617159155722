import PropTypes from 'prop-types';

function DemoLinks({ offers, giftOffers }) {
  return (
    <ul>
      <li>
        API-dokumentation:{' '}
        <a href="https://checkout-api.trhub.trdev.se">
          https://checkout-api.trhub.trdev.se
        </a>
      </li>
      <li>
        Demo embed: <a href="/demo.html">demo.html</a>
      </li>
      {offers.products.map(product => (
        <li key={product._id}>
          Produkt: <a href={`?sku=${product.sku}`}>{product.headline}</a>
        </li>
      ))}
      {offers.campaigns.map(campaign => (
        <li key={campaign._id}>
          kampanj: <a href={`?campaign=${campaign._id}`}>{campaign.name}</a>
        </li>
      ))}
      {offers.products.length > 0 && (
        <li>
          Stacked:{' '}
          <a href={`?sku=${offers.products[0].sku},${offers.products[0].sku}`}>
            products
          </a>
        </li>
      )}
      {offers.campaigns.length > 0 && (
        <li>
          Stacked:{' '}
          <a
            href={`?campaign=${offers.campaigns[0]._id},${offers.campaigns[0]._id}`}
          >
            campaigns
          </a>
        </li>
      )}
      {giftOffers.products.map(product => (
        <li key={product._id}>
          Gåvoprodukt: <a href={`?sku=${product.sku}`}>{product.headline}</a>
        </li>
      ))}
      <li>
        <a href={`?redeem=true`}>Lös in gåva</a>
      </li>
    </ul>
  );
}

DemoLinks.propTypes = {
  offers: PropTypes.object,
  giftOffers: PropTypes.object,
};

export default DemoLinks;
