import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_CLIENT_KEY,
  appVersion: process.env.APP_VERSION,
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production'],
  maxBreadcrumbs: 30,
  plugins: [new BugsnagPluginReact()],
  onError: event => {
    const { originalError } = event;

    if (originalError.isTrusted === true) {
      event.addMetadata('Event error info', {
        eventTag: originalError.target?.tagType,
        eventSrc: originalError.target?.src,
      });
    }
  },
});

export { default } from '@bugsnag/js';
