import { Checkout } from '@trmediaab/checkout';
import themeDemo from '@trmediaab/theme-demo';
import themeJokersystemet from '@trmediaab/theme-jokersystemet';
import themeSpelvarde from '@trmediaab/theme-spelvarde';
import themeStoravinster from '@trmediaab/theme-storavinster';
import themeTravfakta from '@trmediaab/theme-travfakta-legacy';
import themeTravogat from '@trmediaab/theme-travogat';
import themeTravronden from '@trmediaab/theme-travronden';
import themeTravrondenSpel from '@trmediaab/theme-travrondenspel';
import { Box, FlexBox, Text } from '@trmediaab/zebra';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import DemoLinks from '../components/DemoLinks';
import Page from '../components/Page';

const themeMap = {
  demo: ['Demo', themeDemo],
  jokersystemet: ['Jokersystemet', themeJokersystemet],
  spelvarde: ['Spelvärde', themeSpelvarde],
  storavinster: ['Storavinster', themeStoravinster],
  travfakta: ['Travfakta', themeTravfakta],
  travogat: ['Travögat', themeTravogat],
  travronden: ['Travronden', themeTravronden],
  travrondenSpel: ['Travronden Spel', themeTravrondenSpel],
};

function DemoPage({ sku, campaign, redeem, offers, giftOffers, ...props }) {
  const router = useRouter();
  const themeKey = router.query.theme || 'demo';
  const theme = themeMap[themeKey][1];
  const containerVariant = router.query.container || 'wide';

  const onChangeTheme = useCallback(
    e => {
      const urlParams = new URLSearchParams(router.query);
      urlParams.set('theme', e.currentTarget.value);
      router.replace(`${router.pathname}?${urlParams.toString()}`);
    },
    [router],
  );

  const onChangeContainer = useCallback(
    e => {
      const urlParams = new URLSearchParams(router.query);
      urlParams.set('container', e.currentTarget.value);
      router.replace(`${router.pathname}?${urlParams.toString()}`);
    },
    [router],
  );

  if (!sku && !campaign && redeem !== 'true') {
    return <DemoLinks offers={offers} giftOffers={giftOffers} />;
  }

  const [sku1, sku2] = sku?.split(',') || [];
  const [campaign1, campaign2] = campaign?.split(',') || [];

  return (
    <Page theme={theme} px="4" py="6" bg="background.primary">
      <FlexBox
        maxWidth="640px"
        mx="auto"
        alignItems="center"
        justifyContent="center"
        mb="2"
      >
        <Text.strong mr="2">Tema:</Text.strong>
        <select value={themeKey} onChange={onChangeTheme}>
          {Object.entries(themeMap).map(([key, [label]]) => (
            <option key={key} value={key}>
              {label}
            </option>
          ))}
        </select>
        <Text.strong ml="3" mr="2">
          Variant:
        </Text.strong>
        <select value={containerVariant} onChange={onChangeContainer}>
          <option value="narrow">Smal</option>
          <option value="wide">Bred</option>
        </select>
      </FlexBox>

      {sku1 && (
        <Checkout
          sku={sku1}
          campaign={campaign1}
          containerVariant={containerVariant}
          {...props}
        />
      )}
      {sku2 && (
        <Box mt="4">
          <Checkout
            sku={sku2}
            campaign={campaign}
            containerVariant={containerVariant}
            {...props}
          />
        </Box>
      )}
      {campaign2 && (
        <Box mt="4">
          <Checkout
            sku={sku}
            campaign={campaign2}
            containerVariant={containerVariant}
            {...props}
          />
        </Box>
      )}
      {redeem === 'true' && (
        <Box mt="4">
          <Checkout
            mode="redeemGift"
            containerVariant={containerVariant}
            {...props}
          />
        </Box>
      )}
    </Page>
  );
}

DemoPage.propTypes = {
  sku: PropTypes.string,
  campaign: PropTypes.string,
  redeem: PropTypes.string,
  offers: PropTypes.object,
  giftOffers: PropTypes.object,
  orderId: PropTypes.string,
  returnUrl: PropTypes.string,
  site: PropTypes.string,
};

export async function getServerSideProps({ query }) {
  const {
    sku = null,
    campaign = null,
    orderId = null,
    returnUrl = null,
    redeem = null,
  } = query;

  const offers = await fetch(
    `${process.env.CHECKOUT_API_URL}/offers/travronden`,
  ).then(res => res.json());

  const giftOffers = await fetch(
    `${process.env.CHECKOUT_API_URL}/offers/travronden?isGift=true`,
  ).then(res => res.json());

  return {
    props: {
      sku,
      campaign,
      redeem,
      offers,
      giftOffers,
      orderId,
      returnUrl,
      // Needs to be set so the user can login during checkout
      site: 'checkout-embed',
    },
  };
}

export default DemoPage;
