import { Text, ThemeProvider } from '@trmediaab/zebra';
import Head from 'next/head';
import PropTypes from 'prop-types';

import ErrorBoundary from './ErrorBoundary';

const Page = ({ children, theme, ...styleProps }) => {
  const googleFonts = theme.fontFaceDefinitions
    ?.filter(fd => fd.source === 'google')
    .map(fd => `family=${fd.familyPath}`)
    .join('&');

  return (
    <ThemeProvider theme={theme} fonts="/fonts">
      {googleFonts?.length > 0 && (
        <Head>
          <link
            href={`https://fonts.googleapis.com/css2?${googleFonts}&display=swap`}
            rel="stylesheet"
          />
        </Head>
      )}
      <Text.root {...styleProps}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Text.root>
    </ThemeProvider>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
};

export default Page;
